<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Turnos</span>
      </v-col>
      <v-col>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small absolute right v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in submenu_items"
              :key="index"
              link
              :to="{ name: item.link }"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- <v-btn to="/contrataciones/alta" icon small absolute right fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row
      ><v-col>
        <TurnosFilter @turnos-changed="sendturnos" :pageNumber="pageNumber">
        </TurnosFilter> </v-col
    ></v-row>
    <v-row>
      <v-col>
        <TurnosTable
          ref="turnosTable"
          :turnos="this.turnos.results"
          :turnosCount="this.turnos.count"
          @paginate="handlePagination"
        >
        </TurnosTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TurnosTable from "../components/TurnosTable.vue";
import TurnosFilter from "../components/TurnosFilter.vue";

export default {
  name: "Turnos",
  components: {
    TurnosTable,
    TurnosFilter,
  },
  data() {
    return {
      submenu_items: [
        {
          title: "Agregar turno",
          index: 1,
          link: "TurnoAlta",
          icon: "mdi-plus",
        },
        {
          title: "Asignar turno",
          index: 2,
          link: "TurnoAsignar",
          icon: "mdi-clock-plus-outline",
        },
      ],
      turnos: [],
      pageNumber: "1",
      loading: false,
    };
  },
  methods: {
    sendturnos(turnos) {
      // this.pageNumber = "1";
      this.turnos = turnos;
      this.$refs.turnosTable.loading = false;
    },

    handlePagination(pageNumber) {
      //console.log("paginate to: " + pageNumber);
      this.pageNumber = pageNumber;
    },
  },
};
</script>
