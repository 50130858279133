<template>
  <div>
    <v-expansion-panels flat tile>
      <v-expansion-panel>
        <v-expansion-panel-header>Filtro</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form @submit.prevent="doFilter">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field label="Texto" v-model="search"></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  :items="tiposturno"
                  item-text="nombre"
                  item-value="id"
                  label="Tipo de Turno"
                  v-model="tipoturno"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  :items="estados"
                  item-text="nombre"
                  item-value="id"
                  label="Estado"
                  v-model="estado"
                ></v-select>
              </v-col>

              <v-col cols="2" md="1" class="col-btn-filter">
                <v-btn
                  icon
                  type="submit"
                  color="secondary"
                  dark
                  @click="doFilter(true)"
                  ><v-icon>mdi-text-search</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import EmpleadosService from "@/services/EmpleadosService.js";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "TurnosFilter",
  data() {
    return {
      estados: [
        { id: 1, nombre: "Activos" },
        { id: 0, nombre: "Inactivos" },
        { id: 2, nombre: "Todos" },
      ],
      estado: 1,
      tipoturno: 0,
      tiposturno: [],
      search: "",
      turnos: [],
    };
  },
  props: {
    pageNumber: {
      type: String,
      required: false,
    },
  },
  created() {
    PresentismoService.getTurnosTipos()
      .then((response) => {
        // console.log(response.data.results);
        // console.log("gulaaaaaa");
        this.tiposturno = response.data;
        this.tiposturno.push({ id: 0, nombre: "Todos" });
        this.tipoturno = 0;
        this.doFilter();
      })
      .catch((error) => console.log(error));
  },
  methods: {
    doFilter(resetPage = false) {
      let page = "1";
      if (resetPage) page = 1;
      else page = this.pageNumber;

      this.loading = true;
      PresentismoService.getTurnos(this.search, this.tipoturno, this.estado, page)
        .then((response) => {
          this.turnos = response.data;
          this.$emit("turnos-changed", this.turnos);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
      //console.log("haciendo filter con page " + this.pageNumber);
    },
  },
  watch: {
    pageNumber: function (newVal, oldVal) {
      // watch it
      if (newVal && newVal.length) this.doFilter();
    },
  },
};
</script>

<style>
.col-btn-filter {
  padding-top: 25px;
}
</style>
